<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img loading="lazy" class="wp-image-23597 aligncenter" src="@/assets/news/21/1.jpg" alt="图片" width="679" height="338"  sizes="(max-width: 679px) 100vw, 679px"></p>
        <p>英国内政部于当地时间2月17日正式宣布，基于国家安全原因考量，对所有新申请人关闭投资移民签证的申请通道，立即生效。英国内政部同时声明，对2008年该计划启动至2015年4月5日计划改革期间发放的投资移民签证审查，结果也将在适当的时候公布。</p>
        <p><img loading="lazy" class="wp-image-23598 aligncenter" src="@/assets/news/21/2.png" alt="图片" width="770" height="1095"  sizes="(max-width: 770px) 100vw, 770px"></p>
        <p>来源：https://www.gov.uk/government/news/tier-1-investor-visa-route-closes-over-security-concerns</p>
        <p><strong>紧急叫停，事出有因</strong></p>
        <p>Tier 1“投资移民签证”于2008年正式出台，十余年来吸引了全球各地的富人家庭移民英国定居。该计划允许投资人投资200万英镑，在英满足一定居住条件5年后可申请永居，如果投资更高金额，还可在更短的时间内申请永居，该签证很受有财力的外国投资者欢迎。</p>
        <p>根据英国内政大臣帕特尔（Priti Patel）的说法，本次事件与投资移民通道牵涉到“安全问题”及“广为滥用”有关。帕特尔在今日的宣布中表示，英国政府对于滥用英国移民制度，是“零容忍”的。根据她的移民新计划，她想确保英国人对移民制度有信心，包括阻止“腐败精英”对国家安全的威胁，并且不让脏钱在英国城市间流通。</p>
        <p>英媒指出，除了反洗钱这个老生常谈的原因，英国政府此次之所以“快刀斩乱麻”，也可能与近期日渐紧张的俄罗斯乌克兰局势有关。当地时间周二（2月15日），英国首相鲍里斯表示，如果俄罗斯入侵乌克兰，英国政府将考虑对俄方采取经济制裁，其中就包括审查非法资金。而说到俄罗斯在英国筹集非法资金的途径，就不得不提投资移民签证。英国内政部数据显示，自2008年启动以来，英国内政部已向俄罗斯公民发放了14,516份投资移民签证。</p>
        <p><strong>对已经获得英国投资签证的人有影响吗？</strong></p>
        <p>根据内政部于当地时间2月17日发布的移民法修订通知《Statement of changes to the Immigration Rules: CP 632》，投资移民申请通道将不再接纳新申请，但已经拥有该类签证的申请人，仍可在该通道下继续申请续签或申请永居。也就是说，后期的续签和永居还是会按旧政继续执行，已经获得签证的投资人不必担心。</p>
        <p><img loading="lazy" class="wp-image-23599 aligncenter" src="@/assets/news/21/3.png" alt="图片" width="689" height="169"  sizes="(max-width: 689px) 100vw, 689px"></p>
        <p><span style="color: #0000ff;"><strong>移民英国还有哪些方法呢？</strong></span></p>
        <p><span style="color: #0000ff;"><strong>找对方向，事不宜迟</strong></span></p>
        <p>目前投资移民签证关停，而英国首席代表签证通道也在面临不确定性。</p>
        <p>去年年底，为英国移民法律修改提供重要依据和观点的英国移民咨询委员会（Migration Advisory Committee）发布过一份新法案建议。根据这份报告，首席代表签证的永居导向将很可能会被取消。</p>
        <p>但对英国有移民意向的申请人不必担心，在昨天的通告中，内政部两次提及Innovator Visa（创新签证），内政部认为，作为新积分制移民制度的一部分，创新签证会提供一条优质的投资路径，更有效地支持英国经济。值得注意的是，从2019年3月创新签证出台以后，经过近3年法案不断的修复，创新签证已经非常成熟。因投资门槛低、通过率高、申请永居等待期短等优势，创新签证深受有意向移民英国的投资人欢迎。</p>
        <p>但再好的项目也禁不起观望。近年来澳大利亚、塞浦路斯、马耳他、葡萄牙等不少国家的移民政策都发生重大变更，有的大幅涨价，有的直接关停。此次英国投资移民的突然关停再次我们提了醒：移民政策随时可能变化，尤其是像英国这样经济发达、拥有优质教育资源的热门移民目的地，政策更是步步收紧。如果您是希望留英定居的留学生、赴英留学的低龄儿童的陪读妈妈、希望有机会回英国定居的英国海归、希望赴英拓展事业的精英，或是其他有意向移民英国的人士，创新签证可能会是您的优质选择。建议您尽早联系我们做规划，避免因政策变更错过机会，家庭身份规划，宜早不宜迟。</p>
        <p><img loading="lazy" class="wp-image-23600 aligncenter" src="@/assets/news/21/4.jpg" alt="图片" width="633" height="422"  sizes="(max-width: 633px) 100vw, 633px"></p>
        <p><span style="color: #0000ff;"><strong>创新者签证</strong></span></p>
        <p>5万英镑起，要求雅思5.5，适合有计划在英国创业，英语又比较好，且有陪读需求的家庭。</p>
        <p><strong>创新者签证优势</strong></p>
        <p>◆适合英语较好的企业主，高级经理及投资人</p>
        <p>◆自主创业客户，拥有3-5年工作经验</p>
        <p>◆投资门槛低，只需5万英镑</p>
        <p>◆家属可在英国自由工作；孩子可接受英国免费教育</p>
        <p>◆享受全民免费医疗NHS</p>
        <p>◆快速获得永居：主申请人持有创新签证3年</p>
        <p><strong>申请条件</strong></p>
        <p>◆年满18岁</p>
        <p>◆无肺结核</p>
        <p>◆申请人需要有一定的工作经验</p>
        <p>◆投资资金证明：在个人账户下拥有不少于5万英镑的存款</p>
        <p>◆维生资金证明（存满28天）：申请人需1270英磅，配偶需要630镑，第一个孩子需315英镑，之后每增加一个孩子需要增加200英镑</p>
        <p>◆担保证明：必须获得英国内政部认可的担保机构的担保信</p>
        <p>◆英语水平：主申请人达到雅思5.5。如果主申请人符合以下情形中的一种，可直接被视为符合英语要求：</p>
        <p>1.是内政部认可的“majority-English-speaking country国家或地区（如美国、加拿大、澳大利亚、新西兰、马耳他、安提瓜、圣基茨、格林纳达等）的公民</p>
        <p>2.拥有英国或爱尔兰的本科及以上学历等</p>
        <p>3.英国高中GCSE/A Level的英语成绩也可作为递交申请的语言成绩。</p>
        <p><img loading="lazy" class="wp-image-23601 aligncenter" src="@/assets/news/21/5.png" alt="图片" width="665" height="440"  sizes="(max-width: 665px) 100vw, 665px"></p>
        <p>如果您想了解更多详细内容，申请条件等，可拨打免费咨询热线：13764320113，跃迁将安排专业移民顾问为您答疑解惑。</p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "news21",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:21,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>